import React, { useState } from "react";
import SideBar from "../../components/SideBar";
import NotificationInspiration from "./components/NotificationInspiration";
import "./Notifications.css";
import Anthropic from "@anthropic-ai/sdk";
import { getAllFcmTokens } from "../../Firebase/notification";
import { db, functions } from "../../Firebase/firebase-config";
import { httpsCallable } from "firebase/functions";

// Add this test function inside your component
const testFcmTokens = async () => {
  try {
    console.log("Testing FCM token retrieval...");
    const tokens = await getAllFcmTokens();
    console.log("Retrieved tokens:", tokens);
    console.log("Total tokens found:", tokens.length);
  } catch (error) {
    console.error("Error testing FCM tokens:", error);
  }
};

const prompt = `You are tasked with creating four engaging push notification ideas for Blitzo, a
premium same-day delivery service specializing in high-value items across London.
Blitzo's brand voice is witty, personable, and efficient, aiming to make logistics feel
effortless and even enjoyable for its users. Today is {date}, the day of the week is
{day_of_week}, and the current time is {time_of_day}. Consider these factors to ensure
the notifications are timely and relevant. Each notification should have a concise title
(maximum of 4 words) and a brief body 12 sentences). The response format must be
a JSON array of objects, each containing 'title' and 'body' fields. Here are examples to
guide the tone and style we are aiming for:
Title: \"🤫 Customer Confessions: The Great Escape\"
Body: \"Used Blitzo to deliver my house keys to myself... locked inside my own
home. Innovation or desperation? You decide. 🔑🚪\"
Title: \"📦 Box Puns: Handle With Care\"
Body: \"I got a package marked 'fragile' — turns out it was my sense of humor!
Don't worry, we handle all packages with kid gloves. Even the punny ones. 😉\"
Title: \"🛋 Couch Potato Alert!\"
Body: \"Saturday plans: Netflix and... errands? Nah. We'll run around London, you
stay horizontal.\"
Ensure the notifications are creative, align with Blitzo's brand voice, and provide value
to the users.`;

// Debug logs for environment variables
console.log("All env vars:", process.env);
console.log("Specific key:", process.env.REACT_APP_CLAUDE_API_KEY);
console.log("API Key length:", process.env.REACT_APP_CLAUDE_API_KEY?.length);

const generateRandomNotification = () => {
  const titles = [
    "Your shopping bags are heavy! 🏋️‍♀️",
    "New deals just for you! 🎉",
    "Don't miss out on these offers! 🛍️",
    "Exclusive discounts available now! 💸",
    "Shop now and save big! 🛒",
  ];

  const bodies = [
    "But our biceps are ready. Click & Collect from your fave stores, we'll do the lifting📦",
    "Check out the latest deals and save on your favorite items!",
    "Hurry up! These offers are available for a limited time only.",
    "Enjoy exclusive discounts on a wide range of products. Shop now!",
    "Get the best deals and save big on your next purchase. Shop today!",
  ];

  const randomIndex = Math.floor(Math.random() * titles.length);
  return {
    title: titles[randomIndex],
    body: bodies[randomIndex],
  };
};

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [formData, setFormData] = useState({
    title: "",
    body: "",
  });
  const [generatingLoading, setGeneratingLoading] = useState(false);
  const [sendingLoading, setSendingLoading] = useState(false);

  const handleAdapt = (title, body) => {
    setFormData({ title, body });
  };

  const generateInspirations = async () => {
    setGeneratingLoading(true);
    console.log("Starting API call...");
    console.log("API Key being used:", process.env.REACT_APP_CLAUDE_API_KEY);
    try {
      const now = new Date();
      const date = now.toLocaleDateString();
      const day_of_week = now.toLocaleDateString("en-US", { weekday: "long" });
      const time_of_day = now.toLocaleTimeString();

      const formattedPrompt = prompt
        .replace("{date}", date)
        .replace("{day_of_week}", day_of_week)
        .replace("{time_of_day}", time_of_day);

      const client = new Anthropic({
        apiKey: process.env.REACT_APP_CLAUDE_API_KEY,
        dangerouslyAllowBrowser: true,
      });

      const message = await client.messages.create({
        model: "claude-3-sonnet-20240229",
        max_tokens: 1024,
        messages: [
          {
            role: "user",
            content: formattedPrompt,
          },
        ],
      });

      console.log("API Response:", message);

      console.log("Response Content:", message.content);

      const aiNotifications = JSON.parse(message.content[0].text);
      setNotifications(aiNotifications);
      console.log("Successfully set notifications");
    } catch (error) {
      console.error("Detailed Error:", error);
      console.error("Error Stack:", error.stack);
      console.error("Error Message:", error.message);
      const fallbackNotifications = Array(5)
        .fill(null)
        .map(() => generateRandomNotification());
      setNotifications(fallbackNotifications);
      console.log("Set fallback notifications");
    } finally {
      setGeneratingLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSendingLoading(true);

    const token =
      "dlV_d5pclkdLjtoVEPcGfs:APA91bEPc22MtSS_PxiSf9ikORLzUQZgifSTMuLgNdrsPIo_RPkREaO1UkjeJrQribjw5YzjunQypKLCZFbqzzvQl5yYXXhoEJYIZE48zeKQtY7MQ2ATA3s";

    try {
      const sendMultipleNotifications = httpsCallable(
        functions,
        "sendMultipleNotifications"
      );

      const response = await sendMultipleNotifications({
        title: formData.title,
        body: formData.body,
      });

      console.log("Notifications sent:", response.data);
      alert("Notifications sent successfully!");

      setFormData({ title: "", body: "" });
    } catch (error) {
      console.error("Error sending notifications:", error);
      alert("Failed to send notifications: " + error.message);
    } finally {
      setSendingLoading(false);
    }
  };

  return (
    <div className="adminWrapper">
      <div>
        <SideBar />
      </div>
      <div className="mainContent">
        <h1>Notifications</h1>
        <div className="notifications-grid">
          {notifications.map((notification, index) => (
            <NotificationInspiration
              key={index}
              title={notification.title}
              body={notification.body}
              onAdapt={handleAdapt}
            />
          ))}
        </div>
        <button
          className="generate-button"
          onClick={generateInspirations}
          disabled={generatingLoading}
        >
          {generatingLoading ? "Generating..." : "Generate Inspirations"}
        </button>
        <form className="notification-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="title">Title:</label>
            <input
              type="text"
              id="title"
              value={formData.title}
              onChange={(e) =>
                setFormData({ ...formData, title: e.target.value })
              }
              placeholder="Enter notification title (e.g., 'Your shopping bags are heavy! 🏋️‍♀️')"
            />
          </div>
          <div className="form-group">
            <label htmlFor="body">Body:</label>
            <textarea
              id="body"
              value={formData.body}
              onChange={(e) =>
                setFormData({ ...formData, body: e.target.value })
              }
              placeholder="Enter notification body (e.g., 'But our biceps are ready. Click & Collect from your fave stores, we'll do the lifting📦')"
            />
          </div>
          <button
            type="submit"
            className="submit-button"
            disabled={sendingLoading}
          >
            {sendingLoading ? "Sending..." : "Submit Notification"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Notifications;
