// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDMVv_QywW-bW9uqt1pu3Z7EnkvYdKvBNU",
  authDomain: "blitzoapp.firebaseapp.com",
  projectId: "blitzoapp",
  storageBucket: "blitzoapp.appspot.com",
  messagingSenderId: "459015242364",
  appId: "1:459015242364:web:09933697952422e4f6bff9",
  measurementId: "G-L9VQTYZKEN",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth();
const analytics = getAnalytics(app);
const functions = getFunctions(app);
const db = getFirestore(app);

//Emulator for development
// if (window.location.hostname === "localhost") {
//   connectFunctionsEmulator(functions, "localhost", 5001);
// }
// if (window.location.hostname === "localhost") {
//   connectFirestoreEmulator(db, "localhost", 8080);
// }
export { db, functions, httpsCallable, auth };
