import React from "react";
import { Link, useLocation } from 'react-router-dom';
import logoImage from "../Images/logo.png";
import "./SideBar.css"
import bikeIcon from "../Icons/bike.svg";

function SideBar() {
    const location = useLocation();

    const navItems = [
        { path: '/admin/dashboard', label: 'Dashboard', icon: '' },
        { path: '#', label: 'Riders', icon: '' },
        { path: '/admin/requests', label: 'Requests', icon: '' },
        { path: '/admin/notifications', label: 'Notifications', icon: '' },
      ];

    const isActive = (path) => {
        return location.pathname === path ? "active" : "";
    }
    // return (
    //     <div className="SideBar">
    //         <ul>
    //             <li className={isActive("/admin/dashboard")}><Link to="/admin/dashboard">Dashboard</Link></li>
    //             <li className={isActive("#")}><Link to="#">Pending Reviews</Link></li>
    //             <li className={isActive("#")}><Link to="#"><img src={bikeIcon} alt="bike" /> Riders</Link></li>
    //         </ul>
            
    //     </div>
    //   );
    return (
        <div className="sidebar">
          <div className="logo-container"><img className="logoo" src={logoImage} alt="" /><h1 className="sidebar-title">Blitzo</h1></div>
          
        
            {navItems.map(item => (
                <Link
                key={item.path}
                to={item.path}
                className={`nav-item ${isActive(item.path)}`}
                >
                <span className="nav-icon">{item.icon}</span>
                <span>{item.label}</span>
                </Link>
            ))}
          
          <Link to="#" className="logout-button">
            <span className="nav-icon"></span>
            <span>Log out</span>
          </Link>
        </div>
      );


}



export default SideBar;