import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import "./RiderDashboard.css";
import SideBar from "../../components/SideBar";
import { getStorage, ref, getMetadata } from "firebase/storage";

// Set the worker URL for PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const DocumentView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { url, name, phone, scrollPosition } = location.state || {};
  const [fileType, setFileType] = useState('');
  const [numPages, setNumPages] = useState(null);

  useEffect(() => {
    if (scrollPosition !== undefined) {
      window.scrollTo(0, scrollPosition); // Restore scroll position
    }

    // Fetch metadata to determine the file type
    const fetchMetadata = async () => {
      const storage = getStorage();
      const fileRef = ref(storage, url);
      try {
        const metadata = await getMetadata(fileRef);
        const contentType = metadata.contentType;
        console.log('Content Type:', contentType); // Log the content type
        if (contentType.includes('pdf')) {
          setFileType('pdf');
        } else if (contentType.includes('image/heic')) {
          setFileType('heic');
        } else if (contentType.includes('image/jpeg')) {
          setFileType('jpeg');
        } else if (contentType.includes('image/png')) {
          setFileType('png');
        } else if (contentType.includes('image/gif')) {
          setFileType('gif');
        } else {
          setFileType('unsupported');
        }
      } catch (error) {
        console.error('Error fetching metadata:', error);
        setFileType('unsupported');
      }
    };

    fetchMetadata();
  }, [scrollPosition, url]);

  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const renderContent = () => {
    console.log('File Type:', fileType); // Log the file type
    switch (fileType) {
      case 'pdf':
        return (
          <div className="pdf-viewer">
            <Document
              file={url}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={(error) => console.error('Error loading PDF:', error)} // Log PDF load errors
            >
              {Array.from(
                new Array(numPages),
                (el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                  />
                ),
              )}
            </Document>
          </div>
        );
      case 'heic':
        return <p>HEIC files are not supported for direct rendering. Please convert to JPEG or PNG.</p>;
      case 'jpeg':
      case 'png':
      case 'gif':
        return <img src={url} alt="Document" className="document-image" onError={(e) => console.error('Image load error:', e)} />;
      default:
        return <p>Unsupported file type.</p>;
    }
  };

  return (
    <div className="document-viewer">
      <SideBar />
      <div className="document-content">
        <button onClick={handleBack} className="back-button">Back</button>
        <div className="rider-info">
          <p><strong>Name:</strong> {name}</p>
          <p><strong>Phone:</strong> {phone}</p>
        </div>
        {renderContent()}
      </div>
    </div>
  );
};

export default DocumentView;